import React from "react"
import { Link } from "gatsby"

export default function NotFound() {
  return (
    <div className="bg-pink-100 h-screen flex">
      <div className="text-center w-full mx-auto my-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 className="text-4xl font-extrabold text-black dark:text-white sm:text-5xl">
          <span className="block">404: Page Not Found 😓</span>
        </h2>
        <p className="text-xl mt-4 max-w-md mx-auto text-gray-400">
          and we don't know where it went...
        </p>
        <div className="lg:mt-0 lg:flex-shrink-0">
          <div className="mt-12 inline-flex rounded-md shadow">
            <Link to="/">
              <button
                type="button"
                className="py-4 px-6 bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              >
                Return to Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
